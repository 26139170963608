import { Appointment, CancelAppointmentRequestStruct, Place, Service } from '@2meters/types'
import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { cancelTicket } from 'api/backend/cancelTicket'
import { inviteAppointment } from 'api/backend/inviteAppointment'
import { rejectAppointment } from 'api/backend/rejectAppointment'
import { LOGO, VISITOR } from 'app/routes'
import { CalendarIcon, ChevronRigthIcon, ClockIcon, ExclamationTriangleIcon } from 'assets/icons'
import { ButtonLoading } from 'components/Buttons'
import { Modal, ModalContent, ModalIcon } from 'components/Modal'
import { RoundSpinner } from 'components/Spinner'
import { Timestamp } from 'firebase/firestore'
import { DateTime, Settings } from 'luxon'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from 'store/useCurrentUser'
import { useRestrictions } from 'store/useRestrictions'
import FormattedMessage from 'utils/FormattedMessage'
import { ticketOptions } from 'utils/colorCodes'
import { useTranslation } from 'utils/useTranslation'
import { TicketOtionsType, TicketTabType } from '../types'
import { TabDetails } from './TabDetails'
import { TabInfo } from './TabInfo'
import { TabPersonal } from './TabPersonal'
import { TabProducts } from './TabProducts'
import { Tabs } from './Tabs'

interface TicketProps {
  app: Appointment
  place: Place
  services?: Service[]
}

export const Ticket = ({ app, place }: TicketProps) => {
  const intl = useTranslation()
  const [ticketDate, setTicketDate] = useState('')
  const [ticketTime, setTicketTime] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const navigate = useNavigate()
  const userLocale = window.navigator.language
  Settings.defaultLocale = userLocale

  const [loadingLogo, setLoadingLogo] = useState(false)
  const [loadingAction, setLoadingAction] = useState(false)
  const [currentTab, setCurrentTab] = useState<TicketTabType>({
    name: intl({
      id: 'details',
    }),
    current: true,
  })
  const [ticketOption, setTicketOption] = useState<TicketOtionsType>()
  const [showCancelTicketModal, setShowCancelTicketModal] = useState(false)

  const { authUser } = useCurrentUser()
  const { isCounter, canUseScanner } = useRestrictions()
  const canInvite =
    app?.status === 'created' || app?.status === 'confirmed' || app?.status === 'queued'
  const isOwnerOfTicket = authUser?.uid === app?.userId

  useEffect(() => {
    if (app) setTicketOption(ticketOptions(app))
    const startTime = app?.startTime || app?.createdAt
    const dateObject = startTime instanceof Timestamp ? startTime.toDate() : startTime
    const luxonDateTime = DateTime.fromJSDate(dateObject as Date)

    setTicketDate(startTime ? luxonDateTime.toFormat('EEE, d LLL') : '')
    const formattedTime = luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)
    setTicketTime(startTime ? formattedTime : 'Walk-in')
  }, [app])

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(place?.logo)
      .then(link => {
        setLogoUrl(link)
        setLoadingLogo(false)
      })
      .catch(() => {
        setLogoUrl(LOGO)
        setLoadingLogo(false)
      })
  }, [place?.logo])

  const onTicketCancel = () => {
    const request: CancelAppointmentRequestStruct = {
      placeId: place.id,
      appointmentId: app.id,
    }
    setLoadingAction(true)
    cancelTicket(request)
      .then(() => {
        setLoadingAction(false)
        setShowCancelTicketModal(false)
      })
      .catch(e => {
        setLoadingAction(false)
        setShowCancelTicketModal(false)
        console.error('Error', e)
      })
  }

  const onTicketInvite = () => {
    setLoadingAction(true)
    inviteAppointment({
      placeId: place.id,
      appointmentId: app.id,
      counterName: '',
      removeAfterMin: -1,
    }).finally(() => setLoadingAction(false))
  }

  const onTicketRejected = () => {
    setLoadingAction(true)
    rejectAppointment({
      placeId: place.id,
      appointmentId: app.id,
    }).finally(() => setLoadingAction(false))
  }

  const copyFirestorePathToClipboard = async () => {
    const path = `https://console.firebase.google.com/project/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/firestore/data/places/${app.placeId}/tickets/${app.id}`
    await navigator.clipboard.writeText(path)
  }

  return (
    <div className='flex flex-col items-center justify-center mb-[70px]'>
      <div className='max-w-md w-full h-full mx-auto z-10'>
        <div className='bg-white relative rounded-[10px] pt-4 px-0 my-4 md:m-4 border-[1px] border-gray-200 dark:border-none'>
          <div
            onDoubleClick={copyFirestorePathToClipboard}
            className={`absolute text-white ${ticketOption?.bgColor} w-full rounded-t-lg top-0 left-0 h-[40px] flex items-center justify-between px-4`}
          >
            <div className='flex mr-4'>
              <CalendarIcon className='w-5 h-5 stroke-white mr-1' />
              <p className='text-sm'>{ticketDate}</p>
            </div>
            <div className='flex shrink-0	'>
              <ClockIcon className='w-5 h-5 stroke-white mr-1' />
              <p className='text-sm'>{ticketTime}</p>
            </div>
          </div>
          <div className='mt-10'>
            {/* ====TITLE====== */}
            <div
              className='flex px-4'
              onClick={() =>
                navigate(place ? VISITOR.PLACE.replace(':placeId', place.id) : VISITOR.MAIN)
              }
            >
              {(loadingLogo && (
                <div className='flex items-center justify-center min-w-[40px] h-10'>
                  <RoundSpinner width='w-8' height='h-8' />
                </div>
              )) || (
                <div className='mt-1 flex-shrink-0'>
                  <img
                    className='inline-block h-[40px] w-[40px] rounded-full'
                    src={logoUrl}
                    alt='logo'
                  />
                </div>
              )}
              <div className='mr-4 mt-1 flex-shrink-0'></div>
              <div className='flex flex-col w-full justify-center relative '>
                <div className='flex items-start justify-between pr-6'>
                  <h4 className='text-sm text-gray-700 font-semibold'>{app?.placeName}</h4>
                </div>
                <p className='text-sm text-gray-500'> {app?.serviceName}</p>
                <span className='absolute top-2/4 right-1 -translate-y-2/4'>
                  <ChevronRigthIcon className='w-4 h-4 stroke-current' />
                </span>
                {/* {currentService?.description && (
                  <p className='text-sm text-gray-500'>{currentService.description}</p>
                )} */}
              </div>
            </div>

            <div className='border-b border-dashed my-3 pt-3'>
              <div className='absolute rounded-full w-[7px] h-[10px] bg-custom-bg rounded-r-lg border border-r-0 border-gray-200 dark:border-none top-[120px] right-[-1px]'></div>
              <div className='absolute rounded-full w-[7px] h-[10px] bg-custom-bg rounded-l-lg border border-l-0 border-gray-200 dark:border-none top-[120px] left-[-1px]'></div>
            </div>

            {/* ====STATUS====== */}
            <div className='border-b border-gray-200 pb-5'>
              <div className='flex justify-center items-center mb-[-8px]'>
                <p className='text-sm font-bold mr-1'>
                  <FormattedMessage id='status' />:{' '}
                </p>

                {ticketOption && (
                  <p className={`text-sm font-semibold ${ticketOption?.textColor}`}>
                    <FormattedMessage id={`status.${ticketOption?.label}`} />
                  </p>
                )}
              </div>
            </div>

            {/* ====TABS====== */}
            <Tabs app={app} currentTab={currentTab} onChange={tab => setCurrentTab(tab)} />
            {currentTab?.name ===
              intl({
                id: 'details',
              }) && <TabDetails app={app} place={place} />}
            {currentTab?.name ===
              intl({
                id: 'info',
              }) && <TabInfo app={app} />}
            {currentTab?.name ===
              intl({
                id: 'products',
              }) && <TabProducts app={app} />}
            {currentTab?.name ===
              intl({
                id: 'personal',
              }) && <TabPersonal app={app} />}

            {/* ====FOOTER====== */}
            {!['cancelled', 'rejected', 'processed'].includes(app.status) && isOwnerOfTicket && (
              <div className='mt-5 px-4 flex justify-center'>
                <ButtonLoading
                  onClick={() => setShowCancelTicketModal(true)}
                  style='hover:border-0 bg-red-600 btn-error-fill w-full hover:bg-red-700 border-0'
                  // disabled={app?.status === 'processed'}
                >
                  <FormattedMessage id='cancelVisit' />
                </ButtonLoading>
              </div>
            )}

            {canUseScanner && (
              <div className='mt-5 px-4 flex justify-center'>
                <ButtonLoading
                  onClick={onTicketInvite}
                  style='btn-primary w-full mr-2'
                  disabled={!canInvite || loadingAction}
                  loading={loadingAction}
                >
                  <FormattedMessage id='inviteVisit' />
                </ButtonLoading>
                <ButtonLoading
                  onClick={onTicketRejected}
                  style='btn-error w-full ml-2'
                  disabled={!canInvite || loadingAction}
                  loading={loadingAction}
                >
                  <FormattedMessage id='rejectVisit' />
                </ButtonLoading>
              </div>
            )}
            {!canUseScanner && isCounter && (
              <div className='mt-5 px-4 flex justify-center'>
                <ButtonLoading
                  onClick={onTicketInvite}
                  style='btn-primary w-full mr-2'
                  disabled={true}
                >
                  <FormattedMessage id='restrictions.useScaner.forbidden' />
                </ButtonLoading>
              </div>
            )}
          </div>
          <div className='bg-gray-100 text-center p-2 rounded-b-[10px] mt-3'>
            <p className='text-xs'>
              {ticketOption?.label === 'notConfirmed' ? (
                <FormattedMessage id='ticketBottomInfoText.notConfirmed' />
              ) : (
                <FormattedMessage id='ticketBottomInfoText' />
              )}
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={showCancelTicketModal}
        onClose={() => setShowCancelTicketModal(false)}
        cancelButton={
          <ButtonLoading
            onClick={onTicketCancel}
            loading={loadingAction}
            disabled={loadingAction}
            style='btn-error border-0'
          >
            <FormattedMessage id='dialog.cancelTicket' />
          </ButtonLoading>
        }
        submitButton={
          <ButtonLoading
            onClick={() => setShowCancelTicketModal(false)}
            style='btn-primary border-0'
            disabled={loadingAction}
          >
            <FormattedMessage id='dialog.keepTicket' />
          </ButtonLoading>
        }
      >
        <ModalIcon
          icon={
            <ExclamationTriangleIcon className='h-6 w-6 stroke-orange-600' aria-hidden='true' />
          }
        />
        <ModalContent
          title={intl({
            id: 'dialog.cancelTicket.title',
          })}
          description={intl({
            id: 'dialog.cancelTicket.description',
          })}
        />
      </Modal>
    </div>
  )
}
