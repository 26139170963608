import { RadioGroup } from '@headlessui/react'
import { useMemo, useState } from 'react'
import { useServices } from 'store/useServices'
import { useWizardForm } from 'store/useWizardForm'
import { useTranslation } from 'utils/useTranslation'
import { TitleStep } from '../ui'
import { SelectablePaper } from './SelectablePaper'
import { ServiceDisableWarning } from './ServiceDisableWarning'

export const ServiceStep = () => {
  const intl = useTranslation()
  const { services } = useServices()
  const { wizardForm, updateWizardForm } = useWizardForm()
  const [showDisabledPopup, setShowDisabledPopup] = useState<boolean>(false)

  const servicesOfSelectedCateory = useMemo(
    () => services.filter(service => service.categoryId === wizardForm.serviceCategoryId),
    [services, wizardForm.serviceCategoryId]
  )

  const selectService = (serviceId: string, serviceName: string) => {
    updateWizardForm({
      serviceName,
      serviceId,
      queueId: undefined,
      scheduleId: undefined,
    })
  }

  return (
    <>
      <ServiceDisableWarning
        propShow={showDisabledPopup}
        onClose={() => setShowDisabledPopup(false)}
      />
      <div>
        <TitleStep
          title={intl({
            id: 'wizardForm.serviceList.title',
          })}
          description={intl({
            id: 'wizardForm.serviceList.description',
          })}
        />

        <RadioGroup value={wizardForm.serviceId || ''} className='mb-32 lg:mb-4'>
          <div className='space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-4'>
            {servicesOfSelectedCateory
              .sort((a, b) => (a.position && b.position ? a.position - b.position : 0))
              .map(service => {
                const isDisabled = !service.scheduleId && !service.queueId
                return (
                  <SelectablePaper
                    key={service.id}
                    value={service.id}
                    id={service.id}
                    name={service.name}
                    description={service.description}
                    onClick={() => {
                      if (isDisabled) setShowDisabledPopup(true)
                      else selectService(service.id, service.name)
                    }}
                  />
                )
              })}
          </div>
        </RadioGroup>
      </div>
    </>
  )
}
